import { FC } from 'react';
import IconProps from './IconProps';

const LoginIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2938 16.2071C10.6843 16.5977 11.3175 16.5977 11.708 16.2071C12.0985 15.8166 12.0985 15.1834 11.708 14.7929L10.2938 16.2071ZM8.20802 11.2929C7.81749 10.9024 7.18433 10.9024 6.7938 11.2929C6.40328 11.6834 6.40328 12.3166 6.7938 12.7071L8.20802 11.2929ZM6.7938 11.2929C6.40328 11.6834 6.40328 12.3166 6.7938 12.7071C7.18433 13.0977 7.81749 13.0977 8.20802 12.7071L6.7938 11.2929ZM11.708 9.20714C12.0985 8.81661 12.0985 8.18345 11.708 7.79292C11.3175 7.4024 10.6843 7.4024 10.2938 7.79292L11.708 9.20714ZM7.50091 11C6.94863 11 6.50091 11.4477 6.50091 12C6.50091 12.5523 6.94863 13 7.50091 13V11ZM19.7509 13C20.3032 13 20.7509 12.5523 20.7509 12C20.7509 11.4477 20.3032 11 19.7509 11V13ZM16.375 15.5C16.375 14.9477 15.9273 14.5 15.375 14.5C14.8227 14.5 14.375 14.9477 14.375 15.5H16.375ZM14.375 8.5C14.375 9.05228 14.8227 9.5 15.375 9.5C15.9273 9.5 16.375 9.05228 16.375 8.5H14.375ZM11.708 14.7929L8.20802 11.2929L6.7938 12.7071L10.2938 16.2071L11.708 14.7929ZM8.20802 12.7071L11.708 9.20714L10.2938 7.79292L6.7938 11.2929L8.20802 12.7071ZM7.50091 13H19.7509V11H7.50091V13ZM14.375 15.5V16.375H16.375V15.5H14.375ZM14.375 16.375C14.375 17.2725 13.6475 18 12.75 18V20C14.7521 20 16.375 18.3771 16.375 16.375H14.375ZM12.75 18H6.625V20H12.75V18ZM6.625 18C5.72753 18 5 17.2725 5 16.375H3C3 18.3771 4.62298 20 6.625 20V18ZM5 16.375V7.625H3V16.375H5ZM5 7.625C5 6.72754 5.72754 6 6.625 6V4C4.62297 4 3 5.62297 3 7.625H5ZM6.625 6H12.75V4H6.625V6ZM12.75 6C13.6475 6 14.375 6.72753 14.375 7.625H16.375C16.375 5.62298 14.7521 4 12.75 4V6ZM14.375 7.625V8.5H16.375V7.625H14.375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LoginIcon;
